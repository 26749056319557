import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import HomePage from './pages/Home'

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path={'/swyftValet'} element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;