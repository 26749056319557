import React, { FC, HTMLAttributes } from 'react';

type LogoType = 'dark' | 'light';

interface LogoProps extends HTMLAttributes<HTMLDivElement> {
    type?: LogoType;
}

export const Symbol: FC<LogoProps> = ({
    type = "light",
    ...props
}): JSX.Element => {
    return (
        <svg
            fill="none"
            viewBox="0 0 53 45"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path d="M36.6819 8.31037H26.5771V22.8497H41.1905V12.7906C41.1845 11.5993 40.7067 10.4589 39.8616 9.61918C39.0165 8.77946 37.8732 8.30885 36.6819 8.31037ZM36.8298 18.5118H30.9378V12.6483H35.0138C35.4935 12.6483 35.9537 12.838 36.2939 13.1761C36.6342 13.5142 36.8268 13.9732 36.8298 14.4529V18.5118Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M11.9639 32.9089C11.9714 34.0968 12.4473 35.2337 13.2884 36.0726C14.1294 36.9115 15.2676 37.3846 16.4555 37.3891H26.5602V22.8497H11.9639V32.9089ZM16.3246 27.1876H22.2166V33.0569H18.1633C17.6831 33.0554 17.2229 32.8644 16.8829 32.5254C16.5428 32.1864 16.3503 31.7268 16.3473 31.2466L16.3246 27.1876Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M30.938 33.0569H35.014C35.4947 33.0569 35.9557 32.8663 36.2961 32.527C36.6365 32.1877 36.8285 31.7272 36.83 31.2466V27.1876H30.938V33.0569Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M22.2169 12.6483H18.1637C17.684 12.6482 17.2238 12.838 16.8835 13.1761C16.5433 13.5142 16.3507 13.9732 16.3477 14.4529V18.5118H22.2397L22.2169 12.6483Z" fill={type === 'dark' ? '#474748' : 'white'} />
        </svg>
    );
};

export const SecondaryLogo: FC<LogoProps> = ({
    type = "light",
    ...props
}): JSX.Element => {
    return (
        <svg
            fill="none"
            viewBox="0 0 83 37"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path d="M21.543 18.4776L22.4604 16.5988C23.6175 17.3304 24.9573 17.7214 26.3263 17.7272C27.9295 17.7272 28.5802 17.2348 28.5802 16.5724C28.5802 14.553 21.7833 15.9423 21.7833 11.9356C21.7833 10.1038 23.3865 8.57676 26.7103 8.57676C28.1184 8.54557 29.5116 8.87018 30.7609 9.52053L29.9255 11.4169C28.9347 10.8791 27.8287 10.5885 26.7015 10.5698C25.0982 10.5698 24.4798 11.1238 24.4798 11.8008C24.4798 13.788 31.2767 12.4163 31.2767 16.3761C31.2767 18.1786 29.6588 19.7174 26.3175 19.7174C24.4505 19.7261 22.5776 19.2191 21.543 18.4776Z" fill="#F5BB17" />
            <path d="M49.2224 8.57385L45.5968 19.7115H42.1998L39.9957 12.1789L37.8386 19.7115H34.4416L30.7251 8.57385H34.1426L36.2089 16.6633L38.413 8.57385H41.6957L43.9906 16.6633L45.9632 8.57385H49.2224Z" fill="#F5BB17" />
            <path d="M59.9764 8.57385L53.8946 24.5183H50.4976L52.5171 19.6353L48.0884 8.57385H51.7374L54.2375 16.4552L56.5149 8.57385H59.9764Z" fill="#F5BB17" />
            <path d="M67.9775 5.62525C67.3242 5.52528 66.6655 5.46362 66.005 5.4406C65.5956 5.4002 65.1842 5.4938 64.8326 5.70732C64.6128 5.88318 64.5014 6.21731 64.5014 6.70971V8.57381H67.5643V10.6489H64.5131V19.7349H61.163V10.6489H59.0732V8.57381H61.163V6.88264C61.163 5.51485 61.5069 4.58574 62.1947 4.09529C62.8825 3.60484 63.9768 3.35962 65.4774 3.35962C66.3136 3.37335 67.1484 3.435 67.9775 3.54427V5.62525Z" fill="#F5BB17" />
            <path d="M75.9413 19.8404C75.3249 19.9175 74.7043 19.9567 74.083 19.9577C73.5643 19.9577 73.107 19.9371 72.7172 19.899C72.3023 19.8556 71.894 19.7631 71.5009 19.6235C71.1214 19.4987 70.7706 19.2995 70.4692 19.0373C70.1709 18.7562 69.943 18.4088 69.8038 18.0232C69.6176 17.527 69.528 16.9997 69.54 16.4698V10.6489H67.2891V8.5738H69.54V5.34973H72.8901V8.5738H75.9413V10.6489H72.8901V15.9012C72.8901 16.6691 73.0044 17.1879 73.233 17.4575C73.4646 17.7272 73.9687 17.8591 74.7484 17.8591C75.148 17.8466 75.5465 17.8084 75.9413 17.7448V19.8404Z" fill="#F5BB17" />
            <path d="M49.8735 27.3055L46.8458 34.3399H45.2454L42.2295 27.3055H43.9881L46.116 32.3234L48.2731 27.3055H49.8735Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M54.8295 32.8246H51.582L50.9636 34.3281H49.3047L52.4203 27.2938H54.0206L57.1479 34.3281H55.448L54.8295 32.8246ZM54.3196 31.5906L53.2117 28.9029L52.1008 31.5906H54.3196Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M57.8774 27.3055H59.4953V33.0033H63.0125V34.3281H57.8774V27.3055Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M69.3261 33.0239V34.3281H63.9126V27.2938H69.1883V28.5981H65.5217V30.1251H68.7692V31.3884H65.5217V33.0121L69.3261 33.0239Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M72.0664 28.6303H69.8271V27.3055H75.9236V28.6303H73.6843V34.3281H72.0664V28.6303Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M16.5891 8.42432H12.6528V14.1368H18.3418V10.1858C18.3426 9.71993 18.1585 9.27276 17.8299 8.9425C17.5013 8.61224 17.055 8.42587 16.5891 8.42432V8.42432ZM16.6448 12.4309H14.3499V10.1272H15.9385C16.0322 10.1268 16.1252 10.145 16.2119 10.1806C16.2987 10.2162 16.3775 10.2686 16.444 10.3348C16.5104 10.4009 16.5631 10.4796 16.5991 10.5662C16.6351 10.6528 16.6536 10.7457 16.6536 10.8394L16.6448 12.4309Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M14.3496 15.8397V18.1463H15.9382C16.032 18.1467 16.1249 18.1286 16.2116 18.093C16.2984 18.0573 16.3773 18.0049 16.4437 17.9388C16.5101 17.8726 16.5629 17.7939 16.5989 17.7073C16.6348 17.6207 16.6533 17.5279 16.6533 17.4341V15.8397H14.3496Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M10.9561 12.4309V10.1272H9.36751C9.17912 10.128 8.99871 10.2034 8.86577 10.3368C8.73284 10.4703 8.6582 10.651 8.6582 10.8394V12.4309H10.9561Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M6.96094 14.1367V18.0877C6.96094 18.5541 7.14622 19.0014 7.47602 19.3312C7.80582 19.661 8.25311 19.8462 8.71952 19.8462H12.6558V14.1367H6.96094ZM10.9558 18.1463H9.36727C9.17912 18.1447 8.99916 18.0691 8.86639 17.9358C8.73362 17.8025 8.65872 17.6222 8.65796 17.4341V15.8396H10.9558V18.1463Z" fill={type === 'dark' ? '#474748' : 'white'} />
        </svg>
    );
};

export const PrimaryLogo: FC<LogoProps> = ({
    type = "light",
    ...props
}): JSX.Element => {
    return (
        <svg
            fill="none"
            viewBox="0 0 204 45"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path d="M31.7886 29.1233L33.1445 26.3465C34.8548 27.4278 36.8349 28.0057 38.8583 28.0143C41.2279 28.0143 42.1896 27.2865 42.1896 26.3075C42.1896 23.3228 32.1438 25.3761 32.1438 19.4544C32.1438 16.7469 34.5134 14.49 39.4258 14.49C41.5069 14.4439 43.5661 14.9236 45.4125 15.8849L44.1779 18.6876C42.7135 17.8928 41.0788 17.4633 39.4128 17.4357C37.0432 17.4357 36.1292 18.2544 36.1292 19.2551C36.1292 22.1922 46.175 20.1648 46.175 26.0173C46.175 28.6814 43.7837 30.9557 38.8453 30.9557C36.0859 30.9687 33.3177 30.2193 31.7886 29.1233Z" fill="#F5BB17" />
            <path d="M72.6992 14.4857L67.3406 30.947H62.3199L59.0623 19.8139L55.874 30.947H50.8533L45.3604 14.4857H50.4114L53.4654 26.4418L56.723 14.4857H61.5748L64.9667 26.4418L67.8821 14.4857H72.6992Z" fill="#F5BB17" />
            <path d="M88.5933 14.4857L79.6045 38.0514H74.5838L77.5685 30.8344L71.0229 14.4857H76.4162L80.1114 26.1342L83.4773 14.4857H88.5933Z" fill="#F5BB17" />
            <path d="M100.419 10.1276C99.4531 9.97988 98.4796 9.88875 97.5034 9.85473C96.8983 9.79502 96.2902 9.93335 95.7706 10.2489C95.4457 10.5089 95.2811 11.0027 95.2811 11.7305V14.4856H99.808V17.5526H95.2984V30.9816H90.347V17.5526H87.2583V14.4856H90.347V11.986C90.347 9.96447 90.8553 8.59125 91.8718 7.86637C92.8884 7.14149 94.5057 6.77905 96.7236 6.77905C97.9595 6.79935 99.1933 6.89047 100.419 7.05196V10.1276Z" fill="#F5BB17" />
            <path d="M112.189 31.1375C111.278 31.2515 110.361 31.3093 109.443 31.3108C108.676 31.3108 108 31.2805 107.424 31.2242C106.811 31.16 106.207 31.0233 105.626 30.817C105.065 30.6325 104.547 30.338 104.102 29.9506C103.661 29.535 103.324 29.0216 103.118 28.4517C102.843 27.7183 102.711 26.939 102.728 26.1558V17.5526H99.4014V14.4855H102.728V9.7204H107.68V14.4855H112.189V17.5526H107.68V25.3154C107.68 26.4504 107.849 27.2171 108.187 27.6157C108.529 28.0142 109.274 28.2091 110.426 28.2091C111.017 28.1907 111.606 28.1343 112.189 28.0402V31.1375Z" fill="#F5BB17" />
            <path d="M132.944 14.5148L125.715 31.3107H121.894L114.692 14.5148H118.891L123.972 26.4959L129.123 14.5148H132.944Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M143.3 27.6926H135.546L134.069 31.2827H130.108L137.548 14.4868H141.369L148.836 31.2827H144.777L143.3 27.6926ZM142.082 24.7463L139.437 18.3289L136.785 24.7463H142.082Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M150.577 14.5148H154.44V28.1195H162.838V31.2827H150.577V14.5148Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M177.913 28.1685V31.2827H164.987V14.4868H177.584V17.6011H168.829V21.2472H176.583V24.2634H168.829V28.1405L177.913 28.1685Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M184.457 17.678H179.11V14.5148H193.666V17.678H188.32V31.2827H184.457V17.678Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M24.4677 14.2646H18.6499V22.7075H27.0582V16.8681C27.0594 16.1795 26.7872 15.5186 26.3015 15.0305C25.8158 14.5423 25.1563 14.2669 24.4677 14.2646ZM24.55 20.1864H21.1581V16.7815H23.506C23.6446 16.7809 23.782 16.8077 23.9102 16.8603C24.0384 16.913 24.155 16.9904 24.2532 17.0882C24.3514 17.186 24.4293 17.3023 24.4825 17.4303C24.5356 17.5583 24.563 17.6955 24.563 17.8341L24.55 20.1864Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M21.1572 25.2244V28.6337H23.5051C23.6437 28.6342 23.7811 28.6074 23.9093 28.5548C24.0375 28.5021 24.1541 28.4247 24.2523 28.3269C24.3505 28.2291 24.4284 28.1128 24.4816 27.9848C24.5348 27.8568 24.5621 27.7196 24.5621 27.581V25.2244H21.1572Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M16.1419 20.1863V16.7814H13.794C13.5155 16.7826 13.2489 16.894 13.0524 17.0913C12.8559 17.2886 12.7456 17.5557 12.7456 17.8341V20.1863H16.1419Z" fill={type === 'dark' ? '#474748' : 'white'} />
            <path d="M10.2368 22.7075V28.547C10.2368 29.2363 10.5107 29.8974 10.9981 30.3849C11.4855 30.8723 12.1466 31.1461 12.836 31.1461H18.6538V22.7075H10.2368ZM16.1413 28.6336H13.7934C13.5153 28.6313 13.2493 28.5196 13.0531 28.3225C12.8568 28.1255 12.7461 27.859 12.745 27.581V25.2244H16.1413V28.6336Z" fill={type === 'dark' ? '#474748' : 'white'} />
        </svg>
    );
};