import React, { FC, ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
}

const Button: FC<ButtonProps> = ({
    label,
    ...props
}): JSX.Element => {
    return (
        <button
            className={`
                flex
                py-5 px-8
                text-lg
                font-semibold
                font-primary-regular
                rounded-xl box-border
                bg-yellow
                disabled:bg-gray-8
                text-gray-8
                hover:opacity-70
                cursor-pointer
                tracking-wide
                hover:transition hover:duration-500
            `}
            {...props}
        >
            {label}
        </button>
    );
};

export default Button;