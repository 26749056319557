import React, {
    FC,
    useState,
    useEffect,
} from 'react';

import { PrimaryLogo } from '../../components/logos';
import Button from '../../components/buttons/submit';

import gif from '../../assets/images/gif.gif';
import png from '../../assets/images/png.png';


interface HomeProps { };


const HomePage: FC<HomeProps> = () => {
    const [runGif, setRunGif] = useState<boolean>(false);

    useEffect(() => {
        const changeBackground = () => {
            setRunGif(true);
        }
        setInterval(changeBackground, 60000);
    });

    return (
        <div className={`
            h-screen
            w-screen
        `}>
            <div className={`
                h-screen
                w-screen
                fixed z-10
                bg-gray-8
                bg-opacity-[85%]
                flex flex-col
                items-center
                justify-center
            `}>
                <PrimaryLogo className={`
                    w-80
                    laptop:min-w-[320px]
                `} />
                <span className={`
                    text-center
                    mt-12 mb-4
                    laptop:mt-60
                    text-3xl
                    laptop:text-6xl
                    text-primary
                    font-semibold
                    tracking-tighter
                `}>
                    A new concept in valet has arrived.
                </span>
                <span className={`
                    mt-4
                    text-center
                    text-base
                    laptop:text-lg
                    text-primary
                    tracking-wider
                `}>
                    Join us and be one of the first to experience our novel valet parking application!
                </span>
                <div className={`
                    mt-16
                    w-10/12
                    flex flex-col
                    items-center
                    justify-center
                    laptop:w-4/12
                    laptop:mt-52
                `}>
                    <div
                        className={`
                            mb-2
                            w-full
                            flex
                            items-center
                            justify-center
                        `}
                    >
                        <a href="https://swyftapps.com/swyftValetPortal">
                            <Button label="Create your account" />
                        </a>
                    </div>
                    <div
                        className={`
                            my-5
                            w-full
                            flex
                            items-center
                            justify-center
                        `}
                    >
                        <span className="text-white">
                            Have questions? Reach us at <a href="mailto:sales@swyftapps.com" className="text-yellow font-bold">sales@swyftapps.com</a>
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={`
                    fixed z-0
                    w-screen
                    min-h-screen
                `}
            >
                <img
                    src={runGif ? gif : png}
                    alt="Miami from drone"
                    className={`
                        w-screen
                        min-h-screen
                    `}
                />
            </div>
        </div>
    );
}


export default HomePage;